<template>
  <b-card no-body>
    <b-card-header>
      <b-button
        class="icon-arrow-left"
        :disabled="cantGoPrevious"
        @click="goPrevious()"
      />
      План за <span v-if="form.startDate"> {{ formatDate(form.startDate) }} - {{ formatDate(form.endDate) }}</span>
      <b-button
        class="icon-arrow-right"
        :disabled="cantGoNext"
        @click="goNext()"
      />
      <b-button
        v-if="$store.getters.isAdmin"
        to="/auction-plan/add"
        class="ml-2"
        variant="primary"
        :disabled="loading"
      >
        Добавить
      </b-button>
    </b-card-header>
    <b-card-body :class="{'loading' : loading}">
      <b-form
        novalidate
        class="auction-plan-form"
        @submit.prevent="onSubmit"
      >
        <b-form-group>
          <el-date-picker
            v-model="form.week"
            :disabled="formDisabled"
            type="week"
            :picker-options="pickerOptions"
            format="Неделя WW"
            @change="updateDate('week')"
          />
        </b-form-group>
        <table
          v-if="daysOfWeek"
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <td>
                Менеджер
              </td>
              <td
                v-for="(day, index) in daysOfWeek"
                :key="index"
              >
                {{ formatDate(day) }}
              </td>
            </tr>
          </thead>
          <tbody
            aria-live="polite"
            aria-relevant="all"
          >
            <tr
              v-for="(manager, mindex) in managers"
              :key="'m'+mindex"
            >
              <td>
                {{ manager.name }}
              </td>
              <td
                v-for="(day, index) in daysOfWeek"
                :key="index+'m'+mindex"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.dayPlans['manager'+manager.id][index].planedProfit"
                    :disabled="formDisabled"
                    type="number"
                    autocomplete="false"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="fixed-buttons">
          <b-button
            type="button"
            variant="secondary"
            class="mr-2"
            :class="{'loading' : loading}"
            @click="$router.go(-1)"
          >
            Назад
          </b-button>
          <b-button
            v-if="!formDisabled"
            type="submit"
            variant="primary"
            :disabled="loading"
            :class="{'loading' : loading}"
          >
            Сохранить
          </b-button>
        </div>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  customerGetAuctionPlans,
  customerUpdateAuctionPlan,
  routeOwnersList,
} from '@/services/api';
import moment from 'moment';
import notifications from '@/components/mixins/notifications';

export default {
  name: 'AuctionPlanView',
  mixins: [notifications],
  data() {
    return {
      cantGoPrevious: false,
      cantGoNext: true,
      currentPageNumber: 1,
      managers: [],
      loading: false,
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      form: {
        id: null,
        week: null,
        startDate: null,
        endDate: null,
        dayPlans: {},
      },
    };
  },
  computed: {
    daysOfWeek() {
      if (this.form.startDate) {
        return this.getDaysOfWeek(this.form.week);
      }
      return null;
    },
    formDisabled() {
      console.log(this.form.endDate);
      console.log(this.form.endDate >= new Date());
      return (this.form.endDate < new Date()) || !this.$store.getters.isAdmin;
    },
  },
  async mounted() {
    await this.customersRoutesManagersList();
    await this.getPlan();
  },
  methods: {
    formatDateWithDay(date) {
      const days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
      return days[date.getDay()] + moment(date).format(' DD.MM.YYYY');
    },
    formatDate(date) {
      return moment(date).format(' DD.MM.YYYY');
    },
    getDaysOfWeek(current) {
      const day = new Date(current);
      const week= [];
      // Starting Monday not Sunday
      day.setDate((day.getDate() - day.getDay() +1));
      for (let i = 0; i < 7; i++) {
        week.push(
          new Date(day),
        );
        day.setDate(day.getDate() +1);
      }
      return week;
    },
    async customersRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList( params);
      if (response && response.status === 200) {
        this.managers = response.data.items;
      }
      this.loading = false;
    },
    updateDate() {
      const wDays =this.getWeekDates(this.form.week);
      this.form.startDate = Object.assign(wDays.monday);
      this.form.endDate = Object.assign(wDays.sunday);
      if (!this.form.dayPlans.length) {
        this.managers.forEach((manager)=>{
          this.form.dayPlans['manager'+manager.id]= Array(7);
          this.daysOfWeek.forEach((day, key)=> {
            this.form.dayPlans['manager' + manager.id][key] = {
              planedProfit: 0,
              date: moment(day).format(),
              manager: {
                id: manager.id,
              },
            };
          });
        });
      }
    },
    getWeekDates(d) {
      d = new Date(d);
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
      const monday = new Date(d.setDate(diff));
      const sunday = new Date(d.setDate(diff+6));

      return {monday, sunday};
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const sendData = {...this.form};
        sendData.startDate = moment(this.form.startDate).format();
        sendData.endDate = moment(this.form.endDate).format();
        sendData.dayPlans = [];
        for (const [key, managerPlans] of Object.entries(this.form.dayPlans)) {
          managerPlans.forEach((dayPlan)=>{
            sendData.dayPlans.push(dayPlan);
          });
        }
        const response = await customerUpdateAuctionPlan(this.form.id, sendData);
        if (response && response.status === 200) {
          this.showSuccess('План обновлен');
        }
        this.loading = false;
      }
    },
    validate() {
      if (this.form.endDate> new Date()) {
        return true;
      }
    },
    goNext() {
      this.currentPageNumber--;
      if (this.currentPageNumber <=1) {
        this.cantGoNext = true;
      }
      this.getPlan();
    },
    goPrevious() {
      this.currentPageNumber++;
      this.getPlan();
    },
    async getPlan() {
      this.loading = true;
      const response = await customerGetAuctionPlans({page: this.currentPageNumber});
      if (response && response.status === 200) {
        if (response.data.items[0]) {
          const responseData = response.data.items[0];
          this.form.startDate = new Date(responseData.startDate);
          this.form.endDate = new Date(responseData.endDate);
          this.form.id = responseData.id;
          this.form.week = this.form.startDate;
          this.updateDate();
          responseData.dayPlans.forEach((dayPlan) => {
            const newDate = new Date(dayPlan.date);
            const day = newDate.getDay();
            const dayNumber = day + (day === 0 ? 6 : -1);
            delete dayPlan.manager.agent;
            this.form.dayPlans['manager' + dayPlan.manager.id][dayNumber] = dayPlan;
          });
          this.cantGoNext = this.currentPageNumber === 1;
          if (response.data.totalCount - response.data.currentPageNumber * response.data.numItemsPerPage > 0) {
            this.cantGoPrevious = false;
          } else {
            this.cantGoPrevious = true;
          }
          this.currentPageNumber = response.data.currentPageNumber;
        } else {

        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
  .auction-plan-form{
    min-height: 80vh;
  }
</style>
